* {
  margin: 0;
  padding: 0;

  font-style: normal;
  line-height: normal;
}

body {
  font-family: "Hack Regular";
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
  resize: none;
}

button {
  border: none;
}

/* color */
:root {
  --black1: #000000;
  --black2: #202329;
  --black3: #262932;
  --gray: #d9d9d9;
  --white: #ffffff;
  --yellow: #fff500;
}

/* font */
@font-face {
  font-family: "Hack Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Hack-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/AppleSDGothicNeoR.woff") format("woff");
}

@font-face {
  font-family: Apple SD Gothic Neo SB;
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/AppleSDGothicNeoSB.woff") format("woff");
}
